import React from 'react';
import { Button, useMediaQuery } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function ButtonWithLeftArrow() {
    const handleClick = () => {
        window.open("https://thelegalindustry.com/", "_blank");
    };
    const isMobile = useMediaQuery('(max-width: 768px)');


    return (
        <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={<ArrowBackIosNewIcon sx={{ fontSize: isMobile ? '5px' : '14px' }} />}
        sx={{
            backgroundColor: 'transparent',
            color: 'white',
            borderColor: 'white',
            borderRadius: '14px',
            textTransform: 'none', // evita forzar el texto a mayúsculas
            padding: isMobile ? '4px 10px' : '6px 16px',
            fontSize: isMobile ? '0.8rem' : '0.875rem', // Ajusta la fuente si lo requieres

            // Hover
            '&:hover': {
            borderColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },

            // Foco
            '&.Mui-focusVisible, &:focus': {
            borderColor: '#F67147',
            },

            // Activo (al presionar)
            '&:active': {
            borderColor: '#F67147',
            }
        }}
        >
            {isMobile ? 'LIR' : 'The Legal Industry Reviews'}
        </Button>
    );
}

export default ButtonWithLeftArrow;
