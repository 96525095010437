import React from 'react'; 
import { Routes, Route } from 'react-router-dom';
/* import ResponsiveAppBar from './views/NavBar';
import ResponsiveFootBar from './views/FootBar'; */
import { Box } from '@mui/material';
import PrivacyPolicy from './views/PrivacyPolicy.js';
import TermsAndConditions from './views/TermsAndConditions.js';
import MarketComparative from './views/MarketMonitor/marketComparative.js';
import MarketMonitor from './views/MarketMonitor/marketMonitor.js';
import MarketFirm from './views/MarketMonitor/marketFirm.js';

const App = () => {

  return (
    <>
      {/* <ResponsiveAppBar /> */}
      <Box sx={{ minHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
        <Routes>
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />

          {/* Market Monitor */}
          <Route path='/' element={<MarketMonitor />} />
          <Route path='/market-monitor' element={<MarketMonitor />} />
          <Route path='/market-firm' element={<MarketFirm />} />
          <Route path='/market-comparative' element={<MarketComparative />} />
        </Routes>
      </Box>
      {/* <ResponsiveFootBar /> */}
    </>
  );
};

export default App;
